import ModuleContainer from "../../components/ModuleContainer/ModuleContainer";
import "./Weather.css";
import "../../css/Modules.css";

import MODULES from "../../static/modules.json";

function Weather(props) {
    return (
        <ModuleContainer
            module={MODULES.WEATHER}
            />
    );
}

export default Weather;