import { timeFormat } from "d3";
import IconMarker from "./IconMarker";

import "./TahoeMap.css";

function SurveyQuestion({question, answers, images}) {
    if (!Array.isArray(answers))
        answers = [answers];

    images = images ?? [];

    answers = answers.map((answer, idx) => {
        return (
            <div key={`${question}-${answer}-${idx}`}>
                • { answer }
            </div>
        );
    });

    images = images.map((image, idx) => {
        return (
            <img key={`${question}-image-${idx}`} 
                src={image.url}/>
        )
    })

    return (
        <div className="survey-response">
            <div className="survey-question"> { question } </div>
            <div className="survey-answers"> { answers } </div>
            <div className="survey-images"> {images} </div>
        </div>
    );
}

const format_date = timeFormat("%m/%d/%Y at %I:%M %p");

function SurveyMarker(props) {
    //////////////////////////////////
    // Expected props
    // survey: a survey object from firebase
    // ... any other props that IconMarker excepts

    const survey = props.survey.answers
        .map((a, idx) => {
            return <SurveyQuestion
                key={`survey-question-${idx}-${a.questionLabel}`}
                question={a.questionLabel}
                answers={a.choiceLabels ? a.choiceLabels : a.choiceLabel ? a.choiceLabel : a.textAnswer}
                images={a.images}
                />
        });

    return (
        <IconMarker
            active={props.active}
            position={props.position}
            onClick={() => {
                props.onClick();
            }
        }
            class_name={"survey-map-marker"}
            >
            <div className="survey-marker-popup"> 
                <div>
                    Reported on { format_date(props.survey.createdAt) }    
                </div>

                { survey }                
            
            </div>
        </IconMarker>
    )
}

export default SurveyMarker