import { useOutletContext } from "react-router-dom";
import "./LakeConditions.css"
import StationChart from "./StationChart";
import { TercAPI } from "../../js/terc_api";
import MODULES from "../../static/modules.json";
import { parse_time_range } from "../../js/util";
import { HorizontalLineAnnotation, TextAnnotation } from "./TimePlot";

function LakeLevel(props) {
    const [_, module_container] = useOutletContext();

    const tab_index = module_container.tab_index;
    const bottom_tab_index = module_container.bottom_tab_index;
    const [chart_start_date, chart_end_date] = 
        parse_time_range(
            Object.values(MODULES.LAKE_CONDITIONS.TABS)[tab_index]
            .BOTTOM_TABS[bottom_tab_index]
            .time_range
        );

    const chart_props = {
        "y_label": "LAKE LEVEL (FT)",
        "y_ticks": 7,
        "min_y": 6220,
        "max_y": 6230,
        "annotations": [
            new HorizontalLineAnnotation(6229.1), 
            new HorizontalLineAnnotation(6223),
            new TextAnnotation("Maximum Legal Limit (6229.1 ft)", 0.5, 6229.3, "middle"),
            new TextAnnotation("Natural Rim (6223 ft)", 0.01, 6223 - 0.2, undefined, "hanging"),
        ]
    };

    return (
        <StationChart
            data_type_name={TercAPI.LAKE_LEVEL_NAME}
            chart_props={chart_props}
            start_date={chart_start_date}
            end_date={chart_end_date}
            />
    );
}

export default LakeLevel;