import { Polygon } from "react-leaflet";
import IconMarker from "./IconMarker";
import "./TahoeMap.css";
import { getLatLngCenter } from "../../js/util";

const inactive_src = "https://www.alertwildfire.org/images/map-icons/firecam-inactive.png";
const active_src = "https://www.alertwildfire.org/images/map-icons/firecam-active.png";

function getLatLngAngle(c1, c2) {
    let [lat1, lon1] = c1;
    let [lat2, lon2] = c2;
    let angle = Math.atan2(lat2 - lat1, lon2 - lon1) * 180 / Math.PI;
    return 90 - angle;
}

const path_options = { color: '#C15E5E' }

function CameraMarker(props) {
    const { lat, lon } = props.position;
    const { fov_left, fov_right, fov_center } = props;
    const fov_center_test = getLatLngCenter([fov_left, fov_right]);
    const angle = getLatLngAngle([lat, lon], fov_center_test);
    const image = `<img style="transform: translate(calc(-50% + 5px), calc(-50% + 10px)) rotate(${angle}deg) scale(0.7)" src="${props.active ? active_src : inactive_src}"/>`
    
    return (
        <>
        <IconMarker
            icon={image}
            active={props.active}
            position={props.position}
            onClick={props.onClick}
            >
            <div className="photo-marker-popup"> {props.location} </div>
        </IconMarker>
        {
            props.active &&
            <Polygon pathOptions={path_options} positions={[[lat, lon], fov_left, fov_right]} />
        }
        </>
    )
}

export default CameraMarker;