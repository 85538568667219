import { useOutletContext } from "react-router-dom";
import StationChart from "../LakeConditions/StationChart";

import { TimePlotType } from "../LakeConditions/TimePlot";
import { parse_time_range, MONTHS } from "../../js/util";
import { TercAPI } from "../../js/terc_api";
import MODULES from "../../static/modules.json";

function HistoricalPrecipitation() {
    const [_, module_container] = useOutletContext();

    const tab_index = module_container.tab_index;
    const bottom_tab_index = module_container.bottom_tab_index;
    const [chart_start_date, chart_end_date] = 
        parse_time_range(
            Object.values(MODULES.WEATHER.TABS)[tab_index]
            .BOTTOM_TABS[bottom_tab_index]
            .time_range
        );    

    const month_formatter = (d) => {
        let month = MONTHS[d.getMonth()].substring(0, 3).toUpperCase();
        return [month, ""];
    };

    const chart_props = {
        "y_label": "Average Monthly Precipitation",
        "y_ticks": 7,
        "min_y": 0,
        "plot_type": TimePlotType.Scatter,
        "x_tick_formatter": month_formatter
    };

    return (
        <StationChart
            data_type_name={TercAPI.MONTHLY_PRECIPITATION_NAME}
            chart_props={chart_props}
            start_date={chart_start_date}
            end_date={chart_end_date}
            />
    );
} 

export default HistoricalPrecipitation;