import { useOutletContext } from "react-router-dom";
import StationChart from "../LakeConditions/StationChart";
import "./RiverConditions.css";

import { TercAPI } from "../../js/terc_api";
import MODULES from "../../static/modules.json";
import { parse_time_range } from "../../js/util";


function RiversCreeks(props) {
    const [_, module_container] = useOutletContext();

    const tab_index = module_container.tab_index;
    const bottom_tab_index = module_container.bottom_tab_index;
    const [chart_start_date, chart_end_date] = 
        parse_time_range(
            Object.values(MODULES.RIVER_CONDITIONS.TABS)[tab_index]
            .BOTTOM_TABS[bottom_tab_index]
            .time_range
        );

    const chart_props = {
        "y_label": "DISCHARGE CUBIC FEET PER SECOND",
        "y_ticks": 7,
        "min_y": 0
    };

    const data_cleaning_function = (x) => {
        if (x < 0) return 0;
        return x;
    };

    return (
        <StationChart
            data_type_name={TercAPI.RIVER_DISCHARGE_NAME}
            chart_props={chart_props}
            start_date={chart_start_date}
            end_date={chart_end_date}
            data_cleaning_function={data_cleaning_function}
            />
    );
}

export default RiversCreeks;