import APP_CONFIG from "../static/app_config.json";
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, query, collection, where, orderBy, limit } from "firebase/firestore";
import { createLatLng, isNumeric } from "./util";
import MODULES from "../static/modules.json";
// import STATIC_DB from "../js/db.json";

export class CitizenScienceAPI {
    static initialized = false;
    static reports;
    static num_reads = 0;

    static async initialize() {
        if (CitizenScienceAPI.initialized)
            return;
        
        // Initialize Firebase
        const firebase_config = APP_CONFIG.firebase.config;
        const app = initializeApp(firebase_config);
        const db = getFirestore(app);
        const c = collection(db, "reports");
        CitizenScienceAPI.reports = {};

        const survey_names = Object.values(MODULES.CITIZEN_SCIENCE.TABS)
            .map((tab) => tab.survey_name);

        // survey_names.forEach((survey_name) => {
        //     CitizenScienceAPI.reports[survey_name] = STATIC_DB
        //         .filter((r) => r.title === survey_name)
        //         .filter((r) => r.answers.length > 0)
        //         .filter((r) => !!r.location && isNumeric(r.location.latitude) && isNumeric(r.location.longitude));
        //     CitizenScienceAPI.reports[survey_name]
        //         .forEach((r) => {
        //             r.createdAt = new Date(r.createdAt);
        //             r.location = createLatLng(r.location.latitude, r.location.longitude);
        //         });
        // });

        for (const survey_name of survey_names) {
            CitizenScienceAPI.reports[survey_name] = [];
            const q = query(c, 
                where("title", "==", survey_name),
                orderBy("createdAt", "desc"),
                limit(MODULES.CITIZEN_SCIENCE.MAX_OBSERVATIONS)
                );
    
            let survey_documents = 0;
            try {
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    survey_documents += 1;
                    const report = doc.data();
                    
                    // Ensure report has valid location
                    const valid_location = !!report.location && 
                        isNumeric(report.location.latitude) && 
                        isNumeric(report.location.longitude);
                    if (!valid_location)
                        return;

                    // Skip reports with no answers
                    if (report.answers.length == 0)
                        return;
        
                    report.createdAt = new Date(report.createdAt);
                    report.location = createLatLng(report.location.latitude, report.location.longitude);
        
                    CitizenScienceAPI.reports[survey_name].push(report);
                });
            } catch (err) {
                console.log(err);
                console.log(`Failed to get Citizen Science data for survey '${survey_name}'`);
                CitizenScienceAPI.reports[survey_name] = null;
            }
            
            // console.log(`Read ${survey_documents} documents for survey '${survey_name}'`);
            CitizenScienceAPI.num_reads += survey_documents;
        }
        console.log(`Read ${CitizenScienceAPI.num_reads} documents in total`);

        CitizenScienceAPI.initialized = true;
    }

    static async is_loading() {
        return CitizenScienceAPI.reports === undefined;
    }

    static async is_unavailable() {
        return CitizenScienceAPI.reports === null;
    }

    static async get_reports() {
        try {
            await CitizenScienceAPI.initialize();
        } catch (err) {
            console.log(err);
            CitizenScienceAPI.reports = null;
            throw Error("Failed to initialize Firebase");
        }
        return CitizenScienceAPI.reports;
    }


}