import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import CameraMarker from "../../components/TahoeMap/CameraMarker";
import { clamp, createLatLng } from "../../js/util";

import LIVE_CAMERAS from "../../static/live_cameras.json";

const BASE_URL = "https://www.alertwildfire.org/region/tahoe";

function Live(props) {
    const [[map_markers, setMapMarkers, active_location_idx, setActiveLocation]] = useOutletContext();

    useEffect(() => {
        const camera_map_markers = LIVE_CAMERAS
            .map(({location, name, fov_left, fov_right, fov_center}, idx) => 
                <CameraMarker
                    key={`live-camera-${name}-${idx}`}
                    position={createLatLng(...location)}
                    location={name}
                    onClick={() => setActiveLocation(idx)}
                    active={idx === active_location_idx}
                    fov_left={fov_left}
                    fov_right={fov_right}
                    fov_center={fov_center}
                    />
            )

        setMapMarkers(camera_map_markers);
    }, [active_location_idx]);
    
    const camera_id = LIVE_CAMERAS[clamp(active_location_idx, 0, LIVE_CAMERAS.length - 1)].id;
    const iframe_src = `${BASE_URL}/?camera=${camera_id}&modeFullview=true`;

    return (
        <div className="live-container">
            <iframe 
                title="alert wildfire camera"
                src={iframe_src}>
            </iframe>
        </div>
    );
}

export default Live;