import { useState } from "react";
import { useEffect } from "react";

import ModuleContainer from "../../components/ModuleContainer/ModuleContainer";
import "./CitizenScience.css";
import "../../css/Modules.css";

import MODULES from "../../static/modules.json";

import { CitizenScienceAPI } from "../../js/citizen_science_api";

function CitizenScience(props) {
    const [reports, setReports] = useState(undefined); 
    const reports_loading = reports === undefined;
    const reports_unavailable = reports === null;

    ////////////////////////////////////////////////////
    // Download reports from firebase
    ////////////////////////////////////////////////////
    useEffect(() => {
        CitizenScienceAPI.get_reports()
            .then((r) => {
                setReports(r);
            })
            .catch((err) => {
                setReports(null);
                console.log(err);
            });
    }, []);

    return (
        <div className="module-container">
            {
                reports_loading ? 
                    <div className="citizen-science-msg">
                        Loading Citizen Science data
                    </div>
                    :

                reports_unavailable ?
                    <div className="citizen-science-msg">
                        Citizen Science data is temporarily unavailable
                    </div>
                    :

                <ModuleContainer
                    module={MODULES.CITIZEN_SCIENCE}
                    context={[reports]}
                    />
            }
        </div>
    );
}

export default CitizenScience;