import { useOutletContext } from "react-router-dom";
import "./Weather.css";

import WeatherDisplay from "./WeatherDisplay";
import HistoricalPrecipitation from "./HistoricalPrecipitation";
import MODULES from "../../static/modules.json";
import HistoricalTemperature from "./HistoricalTemperature";


function WeatherRouter() {
    // Not the best solution, feels ugly
    // Have to do some routing in this component based on the bottom tab
    const [[map_markers, setMapMarkers, active_location_idx, setActiveLocation],
        module_container] = useOutletContext();

    const {tab_index, bottom_tab_index} = module_container;
    const tab_name = Object.keys(MODULES.WEATHER.TABS)[tab_index];

    const tab_has_bottom_tabs = MODULES.WEATHER.TABS[tab_name].BOTTOM_TABS !== undefined;
    const bottom_tab_name = (tab_has_bottom_tabs) ?  MODULES.WEATHER
                                                            .TABS[tab_name]
                                                            .BOTTOM_TABS[bottom_tab_index]
                                                            .name : undefined;

    if (!tab_has_bottom_tabs)
        return <WeatherDisplay/>
    if (tab_name === "TEMPERATURE") {
        if (bottom_tab_name === "Today")
            return <WeatherDisplay/>
        if (bottom_tab_name === "Historical")
            return <HistoricalTemperature/>
    }
    else if (tab_name === "PRECIPITATION") {
        if (bottom_tab_name === "Today")
            return <WeatherDisplay/>
        if (bottom_tab_name === "Historical")
            return <HistoricalPrecipitation/>
    }

    throw new Error(`Unknown tab '${tab_name}' and bottom tab ${bottom_tab_name} combination`);
}

export default WeatherRouter;