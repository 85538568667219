import { useState, useEffect } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";

import ModuleBottomTabs from "../../components/TabGroup/ModuleBottomTabs/ModuleBottomTabs";

import "../../css/Modules.css";
import "./Images.css";

import APP_CONFIG from "../../static/app_config.json";
import { clamp, capitalizeFirstLetter, createLatLng, getSeason, http_get, SEASONS } from "../../js/util";
import PhotoMarker from "../../components/TahoeMap/PhotoMarker";

function Photos(props) {
    const [[map_markers, setMapMarkers, active_location_idx, setActiveLocation]] = useOutletContext();
    const [photos_json, setPhotosJson] = useState(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const season = capitalizeFirstLetter(searchParams.get("season") ?? getSeason());
    
    function onSeasonChanged(idx) {
        const season = SEASONS[idx];
        searchParams.set("season", season.toLowerCase());
        setSearchParams(searchParams);
    }

    ///////////////////////////////////////////////////////////////
    // Load photos JSON
    ///////////////////////////////////////////////////////////////
    useEffect(() => {
        const get_photos_json = async () => {
            let response = await fetch(APP_CONFIG.PHOTOS_JSON_URL);
            return await response.json();
        };

        get_photos_json()
            .then((res) => {
                setPhotosJson(res);
            })
            .catch((err) => {
                console.log(`Failed to retrieve ${APP_CONFIG.PHOTOS_JSON_URL} for reason ${err}`)
                setPhotosJson(null);
            });
    }, []);

    ///////////////////////////////////////////////////////////////
    // Set Photo Map Markers
    ///////////////////////////////////////////////////////////////
    useEffect(() => {
        if (photos_json == undefined || photos_json == null) {
            return;
        }

        const PHOTO_MAP_MARKERS = photos_json
            .map(({location, coords}, idx) => 
                <PhotoMarker
                    key={`photo-marker-${idx}`}
                    position={createLatLng(...coords)}
                    location={location}
                    onClick={() => setActiveLocation(idx)}
                    active={idx === active_location_idx}
                    />
            );

        setMapMarkers(PHOTO_MAP_MARKERS);
    }, [photos_json, active_location_idx]);
    ///////////////////////////////////////////////////////////////
    
    let img;
    if (photos_json === undefined) {
        img = (
            <div className="photos-container">
                <div className="photos-placeholder"> 
                    Loading photos
                </div>
            </div>
        );
    }
    else if (photos_json === null) {
        img = (
            <div className="photos-container">
                <div className="photos-placeholder"> 
                    Failed to load photos
                </div>
            </div>
        );
    }
    else {
        const active_location = photos_json[clamp(active_location_idx, 0, photos_json.length - 1)];
        const photos = active_location.photos[season];

        if (photos.length >= 1) {
            let { filename, credit } = photos[0];

            img = (
                <div className="photos-container">
                    <div 
                        className="photos-img" 
                        style={{'backgroundImage': `url(${filename})`}} 
                        alt={active_location.location}
                        />
                    <div className="photos-location"> { active_location.location } </div>
                    <div className="photos-credit"> { credit }</div>
                </div>
            ); 
        } else {
            // Case 'Image not found'
            img = (
                <div className="photos-container">
                    <div className="photos-location"> { active_location.location } </div>
                    <div className="photos-placeholder"> 
                        No image found
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="module-container">
            <div className="module-content">
                { img }
            </div>
            
            <ModuleBottomTabs
                default_tab={SEASONS.indexOf(season)}
                onTabChanged={onSeasonChanged}
                tab_names={SEASONS}
                />
        </div>
    );
}

export default Photos;