import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import SurveyMarker from "../../components/TahoeMap/SurveyMarker";
import "./CitizenScience.css";

import MODULES from "../../static/modules.json";

function AlgaeReports() {
    const [[map_markers, setMapMarkers, active_location_idx, setActiveLocation],
        module_container, [reports]] = useOutletContext();
    
    let filtered_reports = reports[MODULES.CITIZEN_SCIENCE.TABS.ALGAE.survey_name];
    let reports_unavailable = filtered_reports === null;

    //////////////////////////////////////////////////////////
    // Set Map Markers
    //////////////////////////////////////////////////////////
    useEffect(() => {
        if (reports_unavailable) {
            setMapMarkers([]);
            return;
        }

        const markers = filtered_reports
            .map((r, idx) => {
                return <SurveyMarker
                    key={`algae-map-marker-${idx}`}
                    position={r.location}
                    survey={r}
                    />
            });

        setMapMarkers(markers);
    }, []);

    return (
        <div className="fill-parent citizen-science-msg">
            {
                (reports_unavailable) ? "Algae Watch Data is temporarily unavailable" : ""
            }
        </div>
    );
}

export default AlgaeReports;